<template>
  <div ref="gRecaptcha"></div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: ["sitekey"],
  data() {
    return {};
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {
    this.loaded();
    var script = document.createElement("script");
    script.src =
      "https://recaptcha.net/recaptcha/api.js?οnlοad=ReCaptchaLoaded&render=explicit";
    document.head.appendChild(script);
  },

  methods: {
    getToken(token) {
      console.log("token:" + token);
      this.$emit("getValidateCode", token);
    },
    loaded() {
      setTimeout(() => {
        window.grecaptcha.render(this.$refs.gRecaptcha, {
          sitekey: this.sitekey,
          /**
           * res返回的是goole的验证情况，
           * 成功返回字符串
           * 失败不返回字符串
           * 所以根据字符串判断验证情况
           */
          callback: this.getToken
        });
      }, 200);
    }
  }
};
</script>

<style scoped></style>
