/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  // let checkphone =
  computed: {
    ...mapState("user", ["lang"]),

    commonRules() {
      return {
        input: {
          required: true,
          message: this.$t("validation.required"),
          trigger: ["blur", "change"],
          transform(value) {
            return (String(value) || "").trim();
          }
        },
        email: {
          type: "email",
          message: this.$t("validation.email"),
          trigger: "blur",
          transform(value) {
            return (value || "").trim();
          }
        },
        checkbox: {
          type: "array",
          required: true,
          message: this.$t("validation.checkboxRequired"),
          trigger: "change"
        },
        radio: {
          required: true,
          message: this.$t("validation.required"),
          trigger: "change"
        },
        select: {
          required: true,
          message: this.$t("validation.required"),
          trigger: "change"
        },
        phone: {
          validator: (rule, value, callback) => {
            if (value.length < 7) {
              callback(new Error(this.$t("validation.phone")));
            } else {
              callback();
            }
          },
          trigger: "blur",
          transform(value) {
            return (value || "").trim();
          }
        },
        checkEmails: {
          validator: (rule, value, callback) => {
            if (this.form.students.length > 0) {
              const emails = [];
              this.form.students.forEach(student => {
                emails.push(student.email);
              });
              emails.push(this.form.parent_email);
              const originalLegth = emails.length;
              const removeDuplicate = new Set(emails);
              if (removeDuplicate.size !== originalLegth) {
                callback(new Error(this.$t("validation.checkemail")));
              } else {
                callback();
              }
            }
          },
          trigger: "blur"
        },
        "g-recaptcha-response": {
          required: true,
          message: this.$t("validation.required")
        }
      };
    }
  },
  watch: {
    lang() {
      // FIXME: 這邊寫得不好（搞例外），之後再改
      if (this.$route.name === "EnrollmentDetail") {
        return;
      }

      if (this.$refs["form"]) {
        this.$refs["form"].clearValidate();
      }
    }
  },

  methods: {
    validateForm(form) {
      return new Promise(resolve => {
        form.validate(valid => {
          resolve(valid);
        });
      });
    }
  }
};
